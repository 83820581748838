/**
 * Nosy Links
 *
 * Quick Access to stuff
 */

import {
    Box,
    createIcon,
    Divider,
    Heading,
    Link,
    List,
    ListIcon,
    ListItem,
    Text
} from '@chakra-ui/react'
import React from 'react'

const SlackIcon = createIcon({
    displayName: 'SlackIcon',
    viewBox: '0 0 448 512',
    path: (
        <path
            fill='currentColor'
            d='M94.12 315.1c0 25.9-21.16 47.06-47.06 47.06S0 341 0 315.1c0-25.9 21.16-47.06 47.06-47.06h47.06v47.06zm23.72 0c0-25.9 21.16-47.06 47.06-47.06s47.06 21.16 47.06 47.06v117.84c0 25.9-21.16 47.06-47.06 47.06s-47.06-21.16-47.06-47.06V315.1zm47.06-188.98c-25.9 0-47.06-21.16-47.06-47.06S139 32 164.9 32s47.06 21.16 47.06 47.06v47.06H164.9zm0 23.72c25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06H47.06C21.16 243.96 0 222.8 0 196.9s21.16-47.06 47.06-47.06H164.9zm188.98 47.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06h-47.06V196.9zm-23.72 0c0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06V79.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06V196.9zM283.1 385.88c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06v-47.06h47.06zm0-23.72c-25.9 0-47.06-21.16-47.06-47.06 0-25.9 21.16-47.06 47.06-47.06h117.84c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06H283.1z'
        />
    )
})

const MondayIcon = createIcon({
    displayName: 'MondayIcon',
    viewBox: '0 0 175 106',
    path: (
        <React.Fragment>
            <path
                fill='currentColor'
                d='M21.7484 104.965C17.8836 104.973 14.0858 103.955 10.743 102.015C7.40026 100.074 4.63232 97.2809 2.72197 93.92C0.840853 90.5827 -0.0973058 86.7965 0.00798073 82.9667C0.113267 79.1369 1.25803 75.4079 3.31965 72.1792L42.5014 10.6273C44.5068 7.31929 47.354 4.603 50.7522 2.75592C54.1503 0.908849 57.9777 -0.00281945 61.8433 0.11404C65.706 0.20664 69.4747 1.32422 72.7638 3.35242C76.0529 5.38061 78.7443 8.2466 80.5626 11.6571C84.2151 18.5663 83.7585 26.879 79.3838 33.3647L40.2269 94.9165C38.2412 98.0119 35.5065 100.556 32.2763 102.312C29.0461 104.069 25.4249 104.981 21.7484 104.965V104.965Z'
            />
            <path
                fill='currentColor'
                d='M88.9551 104.965C81.0523 104.965 73.7804 100.738 69.9619 93.9449C68.085 90.6168 67.149 86.8404 67.2543 83.0206C67.3596 79.2008 68.5022 75.4818 70.5596 72.2622L109.667 10.8516C111.643 7.49699 114.48 4.73231 117.883 2.84426C121.287 0.956215 125.134 0.0135504 129.025 0.114037C136.994 0.288428 144.216 4.74786 147.869 11.7235C151.496 18.6992 150.965 27.0616 146.458 33.5307L107.359 94.9414C105.379 98.0236 102.656 100.558 99.4393 102.309C96.2229 104.061 92.6173 104.974 88.9551 104.965V104.965Z'
            />
            <path
                fill='currentColor'
                d='M154.659 105.48C165.8 105.48 174.831 96.6345 174.831 85.7236C174.831 74.8126 165.8 65.9675 154.659 65.9675C143.519 65.9675 134.487 74.8126 134.487 85.7236C134.487 96.6345 143.519 105.48 154.659 105.48Z'
            />
        </React.Fragment>
    )
})

const MailIcon = createIcon({
    displayName: 'GoogleMailIcon',
    viewBox: '52 42 88 66',
    path: (
        <React.Fragment>
            <path
                fill='currentColor'
                opacity='0.5'
                d='M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6'
            />
            <path
                fill='currentColor'
                opacity='0.5'
                d='M120 108h14c3.32 0 6-2.69 6-6V59l-20 15'
            />
            <path
                fill='currentColor'
                opacity='0.75'
                d='M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2'
            />
            <path fill='currentColor' d='M72 74V48l24 18 24-18v26L96 92' />
            <path
                fill='currentColor'
                opacity='0.75'
                d='M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2'
            />
        </React.Fragment>
    )
})

const CalendarIcon = createIcon({
    displayName: 'GoogleCalendarIcon',
    viewBox: '186 38 76 76',
    path: (
        <React.Fragment>
            <path
                fill='currentColor'
                opacity='0.75'
                d='M244 114l18-18h-18v18z'
            />
            <path fill='currentColor' opacity='0.5' d='M262 56h-18v40h18V56z' />
            <path fill='currentColor' opacity='0.5' d='M244 96h-40v18h40V96z' />
            <path
                fill='currentColor'
                opacity='0.75'
                d='M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z'
            />
            <path
                fill='currentColor'
                opacity='0.75'
                d='M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z'
            />
            <path
                fill='currentColor'
                opacity='0.5'
                d='M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z'
            />
            <path
                fill='currentColor'
                opacity='0.5'
                d='M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z'
            />
        </React.Fragment>
    )
})

const DriveIcon = createIcon({
    displayName: 'GoogleDriveIcon',
    viewBox: '0 0 512 512',
    path: (
        <path
            fill='currentColor'
            d='M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z'
        />
    )
})

const WebsiteIcon = createIcon({
    displayName: 'WebsiteIcon',
    viewBox: '0 0 512 512',
    path: (
        <path
            fill='currentColor'
            d='M0 96C0 60.65 28.65 32 64 32H448C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96zM96 160C113.7 160 128 145.7 128 128C128 110.3 113.7 96 96 96C78.33 96 64 110.3 64 128C64 145.7 78.33 160 96 160zM448 128C448 114.7 437.3 104 424 104H184C170.7 104 160 114.7 160 128C160 141.3 170.7 152 184 152H424C437.3 152 448 141.3 448 128z'
        />
    )
})

const SanityIcon = createIcon({
    displayName: 'SanityIcon',
    viewBox: '0 0  256 256',
    path: (
        <React.Fragment>
            <path
                d='M53.1769 34C53.1769 67.5361 74.2011 87.4901 116.292 98.0121L160.893 108.199C200.729 117.211 224.987 139.597 224.987 176.067C225.297 191.956 220.053 207.467 210.134 220C210.134 183.613 191.025 163.953 144.934 152.131L101.141 142.322C66.0722 134.441 39.0049 116.038 39.0049 76.4232C38.8204 61.125 43.806 46.2009 53.1769 34Z'
                fill='currentColor'
            />
            <path
                d='M182.553 167.084C201.633 179.084 210 195.867 210 219.952C194.206 239.839 166.46 251 133.848 251C78.9544 251 40.5372 224.441 32 178.287H84.7168C91.5041 199.476 109.475 209.294 133.464 209.294C162.747 209.294 182.212 193.937 182.596 167'
                fill='currentColor'
                opacity='0.5'
            />
            <path
                d='M80.3953 84C71.7012 78.9428 64.5681 71.6762 59.7537 62.9712C54.939 54.2661 52.6225 44.4476 53.0501 34.5578C68.2561 14.9645 94.7068 3 126.95 3C182.749 3 215.035 31.8482 223 72.4525H172.398C166.818 56.4442 152.848 43.9794 127.377 43.9794C100.159 43.9794 81.5878 59.4874 80.5232 84'
                fill='currentColor'
                opacity='0.5'
            />
        </React.Fragment>
    )
})

const NosyLinks = () => {
    return (
        <Box width='full' mx='auto'>
            <Box
                bg='brand_grayscale.dark'
                shadow='base'
                height='full'
                px={{ base: 4, md: 8 }}
                pt='6'
                pb='8'
                borderTopWidth='2px'
                borderColor='gray.500'
                borderTopColor='brand_primary.700'
                borderBottomRadius={{
                    base: 'none',
                    sm: 'base'
                }}>
                <Heading size='md' mb={2} color='gray.50'>
                    Gotta Go Fast
                </Heading>
                <Text fontSize='sm' mb={4} color='gray.200'>
                    Handy quick-links to NOSY products & services
                </Text>
                <Divider mb='6' />
                <List spacing={3} color='gray.50'>
                    <ListItem>
                        <ListIcon as={SlackIcon} color='brand_primary.700' />
                        <Link href='https://nosymarketing.slack.com' isExternal>
                            Slack
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MondayIcon} color='brand_primary.700' />
                        <Link href='https://nosy-design.monday.com/' isExternal>
                            Monday.com
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MailIcon} color='brand_primary.700' />
                        <Link href='https://mail.google.com/' isExternal>
                            Gmail
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CalendarIcon} color='brand_primary.700' />
                        <Link href='https://calendar.google.com/' isExternal>
                            Google Calendar
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={DriveIcon} color='brand_primary.700' />
                        <Link href='https://drive.google.com/' isExternal>
                            Google Drive
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={WebsiteIcon} color='brand_primary.700' />
                        <Link href='https://nosy.agency' isExternal>
                            NOSY website
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListIcon as={SanityIcon} color='brand_primary.700' />
                        <Link href='https://nosy.sanity.studio' isExternal>
                            NOSY Sanity CMS
                        </Link>
                    </ListItem>
                </List>
            </Box>
        </Box>
    )
}

export default NosyLinks
