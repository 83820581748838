/**
 * Nosy Tools
 *
 * Footer
 */
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Flex, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { FaRegLightbulb } from 'react-icons/fa'

const Footer = () => (
    <Flex as='footer'>
        <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            py={3}
            px={[3, 6, 8]}
            color='white'
            width='full'
            bg='brand_primary.700'>
            <Stack direction='row' spacing={4} alignItems='center'>
                <Icon as={FaRegLightbulb} fontSize='xs' h={10} />
                <Text fontWeight='medium'>
                    Help make this better -{' '}
                    <Link
                        href='mailto:aaron@nosy.agency?subject=Nosy%20Tools%20:%20Feedback'
                        isExternal>
                        email your friendly neighbourhood dev-monkey{' '}
                        <ExternalLinkIcon mx='2px' />
                    </Link>
                </Text>
            </Stack>
        </Stack>
    </Flex>
)

export default Footer
