/**
 * Nosy Tools App - Single Page Application
 */
 import {
    Avatar,
    Button, Center, Container, Flex, Heading, SimpleGrid,
    Stack
} from '@chakra-ui/react'
import { getAuth  } from 'firebase/auth'
import { Fragment } from 'react'
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth'
import { FaGoogle } from 'react-icons/fa'
import Footer from './components/footer/footer'
import Header from './components/header/header'
import NosyLinks from './components/nosy-links/nosy-links'
import ScaffoldNewProject from './components/scaffold-new-project/scaffold-new-project'
import firebaseApp from './utils/firebase'

// Get Firebase auth state
const auth = getAuth(firebaseApp)

const App = () => {    
    const [user, loading] = useAuthState(auth)
    // eslint-disable-next-line no-unused-vars
    const [signInWithGoogle, authUser, authLoading] = useSignInWithGoogle(auth)

    return (
        <Fragment>
            <Stack
                bg='brand_grayscale.darkest'
                direction='column'
                minHeight='100vh'
                spacing={12}>
                <Header />
                <Flex flex='1' flexDirection='column'>
                    {user ? (
                        <Container maxW='container.xl' flex='1'>
                            <Heading mb={10} color='gray.50' fontWeight='light'>
                                NOSY
                            </Heading>
                            <SimpleGrid columns={[1,2]} spacing={10}>
                                <NosyLinks />
                                <ScaffoldNewProject />
                            </SimpleGrid>
                        </Container>
                    ) : (
                        <Container
                            maxW='container.xl'
                            flex='1'
                            justifyContent='center'
                            centerContent>
                            <Stack 
                                direction='column'
                                spacing={6}>
                                <Center>
                                    <Avatar 
                                        size='xl'
                                        bg='brand_primary.700' />
                                </Center>
                                <Button
                                    isLoading={loading || authLoading}
                                    loadingText={
                                        loading ? 'Loading...' : null || 
                                        authLoading ? 'Authenticating...' : null
                                    }
                                    onClick={() => signInWithGoogle()}
                                    bg='brand_primary.700'
                                    color='gray.900'
                                    leftIcon={<FaGoogle />}
                                    _hover={{
                                        bg: 'brand_primary.800'
                                    }}>
                                    Login
                                </Button>
                            </Stack>
                        </Container>
                    )}
                </Flex>
                <Footer />
            </Stack>
        </Fragment>
    )
}

export default App
