/**
 * Nosy Tools
 * 
 * Firebase configuration
 */
 import { initializeApp } from 'firebase/app'

const firebaseConfig = {
    apiKey: 'AIzaSyCDPbpDR_SIdfDBOiSebypDSDgpAjaVvGM',
    authDomain: 'nosy-tools.firebaseapp.com',
    projectId: 'nosy-tools',
    storageBucket: 'nosy-tools.appspot.com',
    messagingSenderId: '329651013699',
    appId: '1:329651013699:web:9c90667dfc594a4b1bacf6',
    measurementId: 'G-JY7CH3ZZSM'
}

const firebaseApp = initializeApp( firebaseConfig )

export default firebaseApp



