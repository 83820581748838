/**
 * Nosy Tools
 *
 * Mark
 */
import { Box } from '@chakra-ui/react'
import React from 'react'

const Mark = ({ width = '20px', height = '20px', ...rest }) => (
    <Box
        as='svg'
        xmlns='http://www.w3.org/2000/svg'
        role='img'
        width={width}
        height={height}
        viewBox='0 0 340 340'
        transition='all 0.343s'
        color='white'
        _hover={{
            color: 'brand_primary.700'
        }}
        {...rest}>
        <path
            fill='currentColor'
            d='M138,177.4c13.4,0,24.2,10.9,24.1,24.3c0,13.4-10.9,24.2-24.3,24.1c-13.4,0-24.2-10.9-24.1-24.3
            c-0.1-13.3,10.6-24.1,23.9-24.2C137.7,177.4,137.9,177.4,138,177.4z'
        />
        <path
            fill='currentColor'
            d='M178.2,201.4c-0.2-13.4,11.3-24.2,24.3-24.1c13.4,0.2,24.1,11.1,24.1,24.4c-0.1,11.6-9.2,24.1-24.3,24
            c-13.2,0.1-24-10.5-24.1-23.7C178.2,201.9,178.2,201.7,178.2,201.4z'
        />
        <path
            fill='currentColor'
            d='M170.3,0C76.2,0,0,76.2,0,170.3s76.2,170.3,170.3,170.3s170.3-76.2,170.3-170.3S264.3,0,170.3,0
            C170.3,0,170.3,0,170.3,0z M170.3,265.8c-52.7,0-95.5-42.8-95.5-95.5c0-52.7,42.8-95.5,95.5-95.5c52.7,0,95.5,42.8,95.5,95.5
S223,265.8,170.3,265.8L170.3,265.8z'
        />
    </Box>
)

export default Mark
