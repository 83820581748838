/**
 * Nosy Tools
 *
 * Create React App Entry point
 */
import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet'
import App from './app'
import theme from './assets/theme/theme'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <React.StrictMode>
        <Helmet title='Nosy Tools' />
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    </React.StrictMode>
)
