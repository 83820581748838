/**
 * Nosy Tools
 *
 * Custom Theme
 */
import { extendTheme } from '@chakra-ui/react'

const customThemeConfig = {
    initialColorMode: 'dark',
    // useSystemColorMode: false,
}

const customThemeColors = {
    colors: {
        brand_primary: {
            50: '#dbfffa',
            100: '#afffef',
            200: '#80ffe5',
            300: '#51ffdb',
            400: '#2dfed0',
            500: '#1ee5b8',
            600: '#0eb38e',
            700: '#008066', // * default
            800: '#004d3d',
            900: '#001c13'
        },
        brand_secondary: {
            50: '#ddfef7',
            100: '#b8f4e6',
            200: '#91ead6',
            300: '#68e1c6',
            400: '#40d8b5',
            500: '#27bf9c', // * default
            600: '#199479',
            700: '#0c6a57',
            800: '#004133',
            900: '#001710'
        },
        brand_grayscale: {
            lightest: '#f4f4f4',
            lighter: '#f7f7f7',
            light: '#f1f1f1',
            mid: '#808080',
            dark: '#333333',
            darker: '#121212',
            darkest: '#0a0a0a'
        }
    }
}

const theme = extendTheme( {
    ...customThemeConfig,
    ...customThemeColors
} )

export default theme
