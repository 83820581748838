/**
 * Nosy Tools
 *
 * Header
 *
 */
import {
    Avatar,
    Container,
    Flex,
    IconButton,
    Stack,
    Text
} from '@chakra-ui/react'
import { getAuth, signOut } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { FaSignOutAlt } from 'react-icons/fa'
import firebaseApp from '../../utils/firebase'
import { Mark } from '../brand'

// Get Firebase auth state
const auth = getAuth(firebaseApp)

const Header = () => {
    const [user, loading] = useAuthState(auth)
    
    return (
        <Flex as='header'>
            <Container maxW='container.xl'>
                <Flex
                    alignItems='center'
                    justifyContent='space-between'
                    paddingY={[3, 6]}>
                    <Mark color='brand_primary.700' width={8} height={8} />
                    {user && (
                        <Stack direction='row' alignItems='center' spacing={4}>
                            <Avatar
                                name={user.displayName}
                                size='2xs'
                                src={user.photoURL}
                            />
                            <Text fontSize='sm' color='gray.50'>
                                {user.displayName}
                            </Text>
                            <IconButton
                                colorScheme='blue'
                                onClick={() => {
                                    signOut( auth )
                                }}
                                isLoading={loading}
                                color='brand_primary.700'
                                variant='link'
                                aria-label='Logout'
                                icon={<FaSignOutAlt />}
                            />
                        </Stack>
                    )}
                </Flex>
            </Container>
        </Flex>
    )
}

export default Header
