/**
 * Nosy Tools
 *
 * Scaffold New Project UI
 */
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Select,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react'
import axios from 'axios'
import { useForm } from 'react-hook-form'

const ScaffoldNewProject = (props) => {
    // Set Google Apps Scripts automation script URL
    // const automationScriptUrl =
    //     'https://script.google.com/macros/s/AKfycbwrte8QwHhRyIxY5uiDZJXgBCLCBNcv9ECAJZQnwblEHp5C4EnGQVGhgPkLz3AjwqkP/exec'

    const automationScriptUrl = 'https://script.google.com/macros/s/AKfycbyF01UtJBBsTJ5_qwKT6t9c9AOcrObZFTPN7v4-lQrFYNbfsuBQWzBdHsYt1xry8dUoeQ/exec'

    // Instantiate React Hook Form
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        reset
    } = useForm()

    // Use toasts for confimation/error message
    const toast = useToast()

    // Handle submit
    const onSubmit = async (values) => {
        // Destructure form values
        const { clientName = '', projectName = '', projectType = '' } = values

        // Build request
        const response = await axios.get(automationScriptUrl, {
            params: {
                clientName,
                projectName,
                projectType
            }
        })

        // console.log('🦄 ~ file: scaffold-new-project.js ~ line 60 ~ onSubmit ~ response', response)

        const { data = {} } = response
        const { status = 'fail', message = '' } = data

        toast({
            title:
                status === 'success'
                    ? 'Folders created'
                    : "Something's gone wrong",
            description: message,
            status: status === 'success' ? 'success' : 'error',
            duration: 9000,
            isClosable: true,
            position: 'top-right',
            variant: 'subtle'
        })

        // Reset form on succesful folder creation
        if (status === 'success') {
            reset()
        }
    }

    return (
        <Box width='full' mx='auto'>
            <Box
                bg='brand_grayscale.dark'
                shadow='base'
                px={{ base: 4, md: 8 }}
                pt='6'
                pb='8'
                borderTopWidth='2px'
                borderColor='gray.500'
                borderTopColor='brand_primary.700'
                borderBottomRadius={{
                    base: 'none',
                    sm: 'base'
                }}>
                <Heading size='md' mb={2} color='gray.50'>
                    Scaffold New Project Folders
                </Heading>
                <Text fontSize='sm' mb={4} color='gray.200'>
                    Automagically create project folders on the Nosy Shared
                    Drive
                </Text>
                <Divider mb='6' />
                <Box as='form' onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing='4'>
                        <FormControl isInvalid={errors.clientName}>
                            <FormLabel htmlFor='clientName' color='gray.50'>
                                Client Name
                            </FormLabel>
                            <Input
                                {...register('clientName', {
                                    required: 'Specify a Client Name'
                                })}
                                id='clientName'
                                placeholder='Client Name'
                                type='text'
                                color='gray.50'
                                borderColor='brand_primary.700'
                                focusBorderColor='brand_secondary.500'
                            />
                            <FormErrorMessage>
                                {errors.clientName && errors.clientName.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.projectName}>
                            <FormLabel htmlFor='projectName' color='gray.50'>
                                Project Name
                            </FormLabel>
                            <Input
                                {...register('projectName', {
                                    required: 'Specify a Project Name'
                                })}
                                id='projectName'
                                placeholder='Project Name'
                                type='text'
                                color='gray.50'
                                borderColor='brand_primary.700'
                                focusBorderColor='brand_secondary.500'
                            />
                            <FormErrorMessage>
                                {errors.projectName &&
                                    errors.projectName.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.projectType}>
                            <FormLabel htmlFor='projectType' color='gray.50'>
                                Project Type
                            </FormLabel>
                            <Select
                                {...register('projectType', {
                                    required: 'Specify a Project Type'
                                })}
                                id='projectType'
                                color='gray.50'
                                borderColor='brand_primary.700'
                                focusBorderColor='brand_secondary.500'
                                placeholder='Specify Project Type'>
                                <option value='web'>Web</option>
                                <option value='video'>Video</option>
                            </Select>
                             <FormErrorMessage>
                                {errors.projectType &&
                                    errors.projectType.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Button
                        isLoading={isSubmitting}
                        size='sm'
                        bg='brand_primary.700'
                        color='gray.50'
                        mt='8'
                        _hover={{
                            bg: 'brand_primary.800'
                        }}
                        type='submit'>
                        Create Project Folders
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ScaffoldNewProject
